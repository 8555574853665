<template>
	<div class="p-card" style="padding: 2rem 2rem 0rem 2rem; border-radius: 10px;"> 
        <div v-if="displayLogin">
            <h5 class=" p-d-flex p-jc-center">
                <strong>INICIAR SESIÓN</strong>
            </h5>
            <div class="p-grid p-fluid p-justify-center">
                <div class="p-col-12 p-md-12">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-user"></i>
                        </span>
                        <InputText v-model="correo" type="email"  placeholder="Correo"/>
                    </div>
                </div>
                <div class="p-col-12 p-md-12">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-user"></i>
                        </span>
                        <InputText v-model="clave" type="password" @keyup.enter="Login" placeholder="Contraseña"/>
                    </div>
                </div>
                <div class="p-col-6 p-md-6">
                    <Button label="Ingresar" icon="pi pi-check" class="p-button-rounded p-button-secondary  p-mr-2 p-mb-2" @click="Login"/>
                </div>
                <div class="p-col-6 p-md-6">
                    <Button label="Recuperar" icon="pi pi-key" class="p-button-rounded p-button-warning p-mb-2" @click="openRecovery"/>
                </div>
            </div>
        </div>
        <div v-if="displayRecovery">
            <h5 class=" p-d-flex p-jc-center">
                <strong>RECUPERACIÓN DE CONTRASEÑA</strong>
            </h5>
            <div class="p-grid p-fluid p-justify-center">
                <div class="p-col-12 p-md-12">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-user"></i>
                        </span>
                        <InputText v-model="correo" type="email" disabled  placeholder="Correo"/>
                    </div>
                </div>
                <div class="p-field p-grid" v-if="!validar">
                    <label class="p-col-12 p-md-4">Código Validación</label>
                    <div class="p-col-12 p-md-8">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-unlock"></i>
                            </span>
                            <InputText v-model="codigo" type="text" @keyup.enter="Verificar" :disabled="validar == true"/>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12">
                        <div class="p-inputgroup">
                            Fue enviado a su correo el código de validación, verifique ...
                        </div>
                    </div>
                </div>
                <div class="p-field p-grid" v-if="validar">
					<div class="p-col-12 p-md-6">
						<label>Contraseña</label>
                        <InputText v-model="clave" type="password"/>
					</div>
					<div class="p-col-12 p-md-6">
						<label>Confirmar Contraseña</label>
                        <InputText v-model="claveconf" type="password"/>
					</div>
                    <div class="p-col-12 p-md-12">
						La clave debe tener una longitud mínima de 8, mayúsculas, minísculas, números y un carácter especial ! @ # $ % ^ & * _ .
					</div>
                </div>
                <div class="p-col-6 p-md-6"  v-if="validar">
                    <Button label="Aceptar" icon="pi pi-check" class="p-button-rounded p-button-secondary" @click="Reset"/>
                </div>
                <div class="p-col-6 p-md-6"  v-else>
                    <Button label="Verificar" icon="pi pi-check" class="p-button-rounded p-button-secondary" @click="ValidarCodigo"/>
                </div>
                <div class="p-col-6 p-md-6">
                    <Button label="Cancelar" icon="pi pi-times" class="p-button-rounded p-button-danger" @click="close"/>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import API from "../service/API";
import Crypto from "crypto-js";

export default {
    props: {
		display: {
			type: Boolean,
			default: false,
		}
	},
    data() {
        return {
			displayLogin: true,
            displayRecovery: false,
            correo: null,
            clave: null,
            claveconf: null,
            validar: false,
            codigo: null,
            codigosend: null,
            size: '40vw',
            visibleFull: false
		}
	},
    created() {    
        this.correo = process.env.VUE_APP_LOGIN;
        this.clave = process.env.VUE_APP_PASS;
        if (this.$store.state.mobile){this.size = '80vw';}
    },
    methods: {
        limpiar() {
            this.clave = null;
            this.claveconf = null;
            this.validar = false;
            this.displayRecovery = false;
            this.codigo = null;
            this.codigosend = null;
            this.$store.state.token = null;
        },
        openRecovery() {
            if (this.correo == null) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca correo', life: 10000});
            } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
                test(this.correo)) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Correo', life: 10000});
            } else {
                const Consult = new API('Autenticacion','Reset');
                Consult.Procesar(null,{
                    correo: this.correo
                }).then(response => {
                    //this.$store.state.error = response;
                    if(response.email){
                        //alert(response.code);
                        this.codigosend = response.code;
                        this.$toast.add({severity:'success', summary: 'Mensaje', detail: response.message, life: 10000});
                        this.displayLogin = false;
                        this.displayRecovery = true;
                    } else {
                        this.$toast.add({severity:'error', summary: 'Mensaje', detail: response.message, life: 10000});
                    }                          
                });         
            }     
        },
        ValidarCodigo() {
            if (this.codigo == null) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca código validación', life: 10000});
            } else {
                if (this.codigo == this.codigosend) {
                    this.validar = true;
                } else {
                    this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error código de validación', life: 10000});
                }
            }     
        },
        close() {
            this.displayRecovery = false;
            this.displayLogin = true;
            this.limpiar();
        },
        Login() {         
            if (this.correo == null) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca correo', life: 10000});
            } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
                test(this.correo)) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Correo', life: 10000});
            } else if (this.clave == null) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca contraseña', life: 10000});
            } else {  
                const cifrado = Crypto.HmacSHA1(this.clave, "btcoin").toString();
                const Consult = new API('Autenticacion','Login');
                Consult.Procesar(null,{
                    correo: this.correo,
                    clave:  cifrado
                }).then(response => {
                    //this.$store.state.error = response;
                    this.limpiar();
                    if(response.authenticated){
                        this.$toast.add({severity:'success', summary: 'Mensaje', detail: response.mensaje, life: 10000});
                        this.$store.state.display.login = false;                        
                        this.$store.commit('Login',response);
                    } else {
                        this.$toast.add({severity:'error', summary: 'Mensaje', detail: response.mensaje, life: 10000});   
                    }  

                });
            }
        },
        Reset() {
            if (this.clave == null) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Contraseña', life: 10000});
            } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
                test(this.correo)) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Correo', life: 10000});
            } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[_.!@#$%^&*])(?=.{8,})/.test(this.clave)) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Contraseña', life: 10000});
            } else if (this.clave != this.claveconf) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Confirmación', life: 10000});
            } else {
                const cifrado = Crypto.HmacSHA1(this.clave, "btcoin").toString();
                const Consult = new API('Autenticacion','ResetClave');
                Consult.Procesar(null,{
                    correo: this.correo,
                    codigo: this.codigo,
                    clave: cifrado
                }).then(response => {
                    //this.$store.state.error = response;	
                    if(response.email){
                        this.$toast.add({severity:'success', summary: 'Mensaje', detail: response.message, life: 10000});
                        this.limpiar();
                        this.close(); 
                    } else {
                        this.$toast.add({severity:'error', summary: 'Mensaje', detail: response.message, life: 10000});
                    }
                });
            } 
        },
        onMenuToggle(event) {
            this.$emit('aprob-login', event);
            this.$emit('close-login', event);
        },	
	},
    watch: {
		display(newValue) {
			this.displayLogin = newValue;
		}
	},


}
</script>